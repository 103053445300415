
 const BASE_URL ="https://backend.eall.ai/";
//  const BASE_URL ="http://43.229.227.19:3001/";
  // const BASE_URL ="http://localhost:3000/";

 //const BASE_URL = "https://carpetestimate.itsabacus.net/";

export const environment = {
  production: false,
  sample_Excel: BASE_URL + "SampleExcelFile/",
  api_url2: "http://localhost:19705/API2",
  //for dell server
  api_url: BASE_URL + "API2",
  api_url1: BASE_URL + "API",
  api_url3: BASE_URL,
  quote_docspath: BASE_URL,
  upload_file_icon: "assets/images/icon/",
  upload_image_url: "assets/images/",
  //sample Excel

  //for aws
  //api_url: "http://3.29.122.249/BlueWebAPI/API2",

};
